/*global $ formidable*/

import BaseClass from '../base/baseClass';

class RequestReportForm extends BaseClass
{
    init(elem, messages) {
        this.$form = $(elem);
        this.$messages = $(messages);
        if (this.$form.length) {
            this.id = this.$form.attr('id');
            this.initFormSubmit();
            // console.log('Form ' + this.id + ' initialized');
        }
        var totalStep = 0;
        if($('.divsshow').length ) {
            $(".divsshow > .divsection").each(function(e) {
                totalStep = totalStep + e;
                if (e != 0)
                    $(this).hide();
            });
            
            $(".next").click(function (e) {
                e.preventDefault();
                $('html, body').animate({
                    scrollTop: $("#content").offset().top}, 500);
                let group = $(this).closest('.group');
                let group_number = $(this).data('grpnum');
                // let group_name = $(this).data('group');
                if ($('#request-repair-field-issue-description').length){
                    $("#request-repair-field-issuetype").find('input[type=radio]').each(function () {
                        if ($(this).is(':checked')) {
                            let radioval = $(this).val();
                            var checkboxarray = [];
                            if(radioval == 'INSIDE YOUR HOME') {
                                checkboxarray = ['Kitchen', 'Bathroom', 'Joinery', 'Electrical', 'Heating / Hot water', 'Plumbing', 'Front/Back Door', 'Windows'];
                            }
                            if(radioval == 'OUTSIDE YOUR HOME') {
                                checkboxarray = ['Roof', 'Chimney', 'Gutter / Downpipes', 'Stonework', 'Windows', 'Drainage'];
                            }
                            if(radioval == 'COMMUNAL AREA') {
                                checkboxarray = ['Backcourt', 'Front/Back Garden', 'Common close', 'Front close door', 'Back close door', 'Controlled entry system'];
                            }
                            $("#request-repair-field-issue-description").find('input[type=checkbox]').each(function () {
                                if($.inArray($(this).val(), checkboxarray) !== -1) {
                                    this.disabled = false;
                                    let checkboxid = this.id;
                                    $('#'+checkboxid).parent().show();
                                } else {
                                    //this.disabled = true;
                                    let disabledid = this.id;
                                    $('#'+disabledid).parent().hide();
                                }
                            });
                        }
                    });
                }
                
                if (window.formidable.getForm('formidable_request_repair').validateGroup($(this).data('group'))) {
                    if ($(".divsshow > .divsection:visible").next().length != 0) {
                        $(".divsshow > .divsection:visible").next().show().prev().hide();
                    } else {
                        $(".divsshow > .divsection:visible").hide();
                        $(".divsshow > .divsection:first").show();
                    }
                    if (group_number == totalStep-1) {
                        $('.next').hide();
                        $('.form-btn').removeClass('d-none').addClass('d-flex');
                    }
                } else {
                    let errors = group.find('.invalid-feedback');
                    if (errors.length) {
                        $('html, body').animate({
                            scrollTop: errors.first().offset().top - 400
                        }, 500);
                        $('.next').show();
                    }
                }
            });
        
            $(".prev").click(function(){
                $('.next').show();
                $('html, body').animate({
                    scrollTop: $("#content").offset().top}, 500);
                if ($(".divsshow > .divsection:visible").prev().length != 0)
                    $(".divsshow > .divsection:visible").prev().show().next().hide();
                else {
                    $(".divsshow > .divsection:visible").hide();
                    $(".divsshow > .divsection:last").show();
                }
                if (!$('.form-btn').hasClass('d-none')) {
                    $('.form-btn').addClass('d-none').removeClass('d-flex');
                }
                return false;
            });
        }
    }

    initFormSubmit() {
        let _this = this;
        this.form.off('submit');
        this.form.submit(function(e) {
            e.preventDefault();
            _this.messages.find('.alert-success').hide();
            _this.messages.find('.alert-danger').remove();
            if (formidable.validateForm(this, e)) {
                $(this).find('[type=submit]').attr('disabled', true);
                let url = $(this).attr('action');
                let form = $(this);
                let formData = new FormData(this);
                $.ajax({
                    url: url,
                    data: formData,
                    contentType: false,
                    processData: false,
                    method: 'POST',
                    dataType: 'JSON'
                }).done((data) => {
                    if (data.success) {
                        let success = _this.messages.find('.alert-success');
                        success.fadeIn();
                        form.trigger('reset');
                        $("#refreshDivID").load("#refreshDivID  .divsshow > *");
                        if (_this.successCallback) {
                            _this.successCallback();
                        }
                        if (_this.scrollToMessage) {
                            $('html, body').animate({ scrollTop: success.offset().top - 170 }, 500);
                        }
                    }
                }).fail((response) => {
                    for (let i in response.responseJSON.errors) {
                        let errors = response.responseJSON.errors[i];
                        for (let j in errors) {
                            $('<div class="alert alert-danger">'+errors[j]+'</div>').appendTo(_this.messages);
                        }
                        $('html, body').animate({ scrollTop: _this.messages.offset().top - 170 }, 500);
                    }
                }).always(() => {
                    window.googleV3Captcha.getCaptcha(form.attr('id')).then(function (token) {
                        form.find('[name=_recaptcha_response]').val(token);
                        form.find('[type=submit]').attr('disabled', false);
                    });
                });
            } else {
                _this.openGroupWithErrors();
            }
        });
    }

    openGroupWithErrors() {
        let first = false;
        $.each(this.form.find('.group'), function (i, group) {
            let errors = $(group).find('.invalid-feedback');
            if (!first && errors.length) {
                first = errors.first();
            }
            if (errors.length) {
                $('html, body').animate({
                    scrollTop: errors.first().offset().top - 400
                }, 500);
            }
        });
        if (first) {
            $('html, body').animate({
                scrollTop: first.offset().top - 200
            }, 500);
        }
    }
}

export default RequestReportForm;