/*global formidable $*/
import BaseClass from '../base/baseClass';
import 'bootstrap-datepicker';

class RetirementForm extends BaseClass
{
    init(elem, messages) {
        this.$form = $(elem);
        this.$messages = $(messages);
        if (this.$form.length) {
            this.id = this.$form.attr('id');
            this.initFormSubmit();
            // console.log('Form ' + this.id + ' initialized');
        }
        this.initNextButtons();
        this.initAddTenant();
        this.initAddPerson();
        this.initAddAddress();
    }
    initDatepickers()
    {
        this.$form.find('.datepicker').datepicker({
            format: 'dd/mm/yyyy',
        });
    }
    initNextButtons() {
        if($('.applydivshow').length ) {
            $(".applydivshow > .applydivsection").each(function(e) {
                totalStep = totalStep + e;
                if (e != 0)
                    $(this).hide();
            });
            var totalStep = 0;
            $(".next").click(function (e) {
                e.preventDefault();
                let group = $(this).closest('.group');
                if($('.datepicker').length) {
                    $('.datepicker').datepicker({
                        format: 'dd/mm/yyyy',
                    });
                }
                $('html, body').animate({
                    scrollTop: $("#content").offset().top}, 500);
                
                if (window.formidable.getForm('formidable_retirement_form').validateGroup($(this).data('group'))) {
                    if ($(".applydivshow > .applydivsection:visible").next().length != 0) {
                        $(".applydivshow > .applydivsection:visible").next().show().prev().hide();
                    } else {
                        $(".applydivshow > .applydivsection:visible").hide();
                        $(".applydivshow > .applydivsection:first").show();
                    }
                    if ($(this).data('group') == 'medical-and-social') {
                        $('.next').hide();
                        $('.form-btn').removeClass('d-none').addClass('d-flex');
                    }
                } else {
                    let errors = group.find('.invalid-feedback');
                    if (errors.length) {
                        $('html, body').animate({
                            scrollTop: errors.first().offset().top - 400
                        }, 500);
                        $('.next').show();
                    }
                }
            });
        
            $(".prev").click(function(){
                $('.next').show();
                $('html, body').animate({
                    scrollTop: $("#content").offset().top}, 500);
                if ($(".applydivshow > .applydivsection:visible").prev().length != 0)
                    $(".applydivshow > .applydivsection:visible").prev().show().next().hide();
                else {
                    $(".applydivshow > .applydivsection:visible").hide();
                    $(".applydivshow > .applydivsection:last").show();
                }
                if (!$('.form-btn').hasClass('d-none')) {
                    $('.form-btn').addClass('d-none').removeClass('d-flex');
                }
                return false;
            });
        }
    }
    initAddTenant() {
        $('.js-add-tenant').click(() => {
            var current_add_person = $('#apply-additional-member').val();
            if(current_add_person == 4){
                alert('Sorry! Maximum 4 members are allowed');
            }
            else {
                var total_add_person = parseInt(current_add_person)+1;
                if(total_add_person == 4){
                    $('.js-add-tenant').css('visibility', 'hidden');
                }
                $('#apply-additional-member').val(total_add_person);
                this.addTenant(total_add_person);
            }
        });
    }
    addTenant(total_add_person) {
        //$('.group-additional-member-' + total_add_person).css('display', 'block');
        var divContent = $('.group-sec-additional-member-' + total_add_person).html();
        var html = '<div class="member'+total_add_person+'">';
        html += '<div class="text-uppercase fw-bold mb-_5">MEMBER ' + total_add_person + '</div>';
        html += '<div class="card-body'+total_add_person+'">' + divContent + '</div>';
        html += '<div class="group-close" data-group="'+ total_add_person+'">';
        html += '<button class="btn btn-sm btn-outline-secondary remove-group mb-2">- Remove Member ' + total_add_person +'</button>';
        html += '</div>';
        html += '</div>';
        $('.group-sec-household-details > .additional-member').append(html);
        $('.group-sec-additional-member-' + total_add_person).html('');
        if($('.datepicker').length) {
            $('.datepicker').datepicker({
                format: 'dd/mm/yyyy',
            });
        }
        this.initRemoveTenant(total_add_person);

    }
    initRemoveTenant(member_id) {
        console.log(member_id);
        $('.group-close').on('click', function(){
            // let member_id = $(this).data('group');
            var divContent = $('.card-body' + member_id).html();
            $('.group-sec-additional-member-' + member_id).html(divContent);
            $('.member'+member_id).remove();
            let current_add_person = parseInt($('#apply-additional-member').val());
            if (current_add_person > 0 ){
                $('#apply-additional-member').val(current_add_person - 1);
            }
            if ((current_add_person - 1) < 4 ) {
                $('.js-add-tenant').css('visibility', 'visible');
            }
        })
    }
    initAddPerson() {
        $('.js-add-person').click(() => {
            var current_add_person = $('#apply-additional-person').val();
            if(current_add_person == 4){
                alert('Sorry! Maximum 4 persons are allowed');
            }
            else {
                var total_add_person = parseInt(current_add_person)+1;
                if(total_add_person == 4){
                    $('.js-add-person').css('visibility', 'hidden');
                }
                $('#apply-additional-person').val(total_add_person);
                this.addPerson(total_add_person);
            }
        });
    }
    addPerson(total_add_person) {
        //$('.group-additional-member-' + total_add_person).css('display', 'block');
        var divContent = $('.group-sec-willing-persons-' + total_add_person).html();
        var html = '<div class="person'+total_add_person+'">';
        html += '<div class="text-uppercase fw-bold mb-_5">Person '+ total_add_person+ '</div>';
        html += '<div class="willing-body'+total_add_person+'">' + divContent + '</div>';
        html += '<div class="person-remove" data-group="'+ total_add_person+'">';
        html += '<button class="btn btn-sm btn-outline-secondary remove-group mb-2">- Remove Person ' + total_add_person +'</button>';
        html += '</div>';
        html += '</div>';
        $('.group-sec-willing-persons > .additional-member').append(html);
        $('.group-sec-willing-persons-' + total_add_person).html('');
        if($('.datepicker').length) {
            $('.datepicker').datepicker({
                format: 'dd/mm/yyyy',
            });
        }
        this.initRemovePerson(total_add_person);

    }
    initRemovePerson(member_id) {
        console.log(member_id);
        $('.person-remove').on('click', function(){
            // let member_id = $(this).data('group');
            var divContent = $('.willing-body' + member_id).html();
            $('.group-sec-willing-persons-' + member_id).html(divContent);
            $('.person'+member_id).remove();
            let current_add_person = parseInt($('#apply-additional-person').val());
            if(current_add_person > 0 ){
                $('#apply-additional-person').val(current_add_person - 1);
            }
            if ((current_add_person - 1) < 4 ) {
                $('.js-add-person').css('visibility', 'visible');
            }
        })
    }
    initAddAddress() {
        $('.js-add-address').click(() => {
            var current_add_address = $('#apply-additional-address').val();
            if(current_add_address == 4){
                alert('Sorry! Maximum 4 addresses are allowed');
            }
            else {
                var total_add_address = parseInt(current_add_address)+1;
                if(total_add_address == 4){
                    $('.js-add-address').css('visibility', 'hidden');
                }
                $('#apply-additional-address').val(total_add_address);
                this.addAddress(total_add_address);
            }
        });
    }
    addAddress(total_add_address) {
        //$('.group-additional-member-' + total_add_person).css('display', 'block');
        var divContent = $('.group-sec-previous-accomodation-' + total_add_address).html();
        var html = '<div class="address'+total_add_address+'">';
        html += '<div class="text-uppercase fw-bold mb-_5">Address '+total_add_address+'</div>';
        html += '<div class="address-body'+total_add_address+'">' + divContent + '</div>';
        html += '<div class="address-remove" data-group="'+ total_add_address+'">';
        html += '<button class="btn btn-sm btn-outline-secondary remove-group mb-2">- Remove Address ' + total_add_address +'</button>';
        html += '</div>';
        html += '</div>';
        $('.group-sec-previous-accomodation > .additional-member').append(html);
        $('.group-sec-extra-address-' + total_add_address).html('');
        if($('.datepicker').length) {
            $('.datepicker').datepicker({
                format: 'dd/mm/yyyy',
            });
        }
        this.initRemoveAddress(total_add_address);

    }
    initRemoveAddress(member_id) {
        console.log(member_id);
        $('.address-remove').on('click', function(){
            // let member_id = $(this).data('group');
            var divContent = $('.address-body' + member_id).html();
            $('.group-sec-previous-accomodation-' + member_id).html(divContent);
            $('.address'+member_id).remove();
            let current_add_address = parseInt($('#apply-additional-address').val());
            if(current_add_address > 0 ){
                $('#apply-additional-address').val(current_add_address - 1);
            }
            if ((current_add_address - 1) < 4 ) {
                $('.js-add-address').css('visibility', 'visible');
            }
        })
    }

    initFormSubmit() {
        let _this = this;
        this.$form.off('submit');
        this.$form.submit(function(e) {
            e.preventDefault();
            _this.hideSuccess();
            _this.removeErrors();
            if (formidable.validateForm(this, e)) {
                _this.disableSubmit()
                let url = $(this).attr('action');
                let formData = new FormData(this);
                $.ajax({
                    url: url,
                    data: formData,
                    contentType: false,
                    processData: false,
                    method: 'POST',
                    dataType: 'JSON'
                }).done((data) => {
                    if (data.success) {
                        _this.showSuccess();
                        _this.reset();
                        $('.form-btn').removeClass('d-flex').addClass('d-none');
                    }
                }).fail((response) => {
                    if (response.responseJSON.errors) {
                        _this.addErrors(response.responseJSON.errors);
                        _this.scrollToMessages();
                    }
                }).always(() => {
                    _this.reloadCaptcha();
                    _this.enableSubmit();
                });
            }
        });
    }

    reset()
    {
        this.$form.trigger('reset');
    }

    enableSubmit()
    {
        this.$form.find('[type=submit]').attr('disabled', false);
    }

    disableSubmit()
    {
        this.$form.find('[type=submit]').attr('disabled', true);
    }

    reloadCaptcha()
    {
        window.googleV3Captcha.getCaptcha(this.id).then((token) => {
            this.$form.find('[name=_recaptcha_response]').val(token);
        });
    }

    removeErrors()
    {
        if (this.$messages.length) {
            this.$messages.find('.alert-danger').remove();
        }
    }

    addErrors(errors)
    {
        if (this.$messages.length) {
            for (let i in errors) {
                let list = errors[i];
                for (let j in list) {
                    this.addError(list[j]);
                }
            }
        }
    }

    addError(error)
    {
        $('<div class="alert alert-danger">'+error+'</div>').appendTo(this.$messages);
    }

    scrollToMessages()
    {
        if (this.$messages.length) {
            $('html, body').animate({ scrollTop: this.$messages.offset().top - 170 }, 500);
        }
    }

    hideSuccess()
    {
        if (this.$messages.length) {
            this.$messages.find('.alert-success').hide();
        }
    }

    showSuccess()
    {
        if (this.$messages.length) {
            let success = this.$messages.find('.alert-success');
            if (success.length) {
                success.fadeIn();
                $('html, body').animate({ scrollTop: success.offset().top - 170 }, 500);
            }
        }
    }
}

export default RetirementForm;