/*global formidable $*/
import BaseClass from '../base/baseClass';
import 'bootstrap-datepicker';

class HousingForm extends BaseClass
{
    init(elem, messages) {
        this.$form = $(elem);
        this.$messages = $(messages);
        if (this.$form.length) {
            this.id = this.$form.attr('id');
            this.initFormSubmit();
            // console.log('Form ' + this.id + ' initialized');
        }
        this.showfile = 0;
        this.showother = 0;
        this.initNextButtons();
        this.initAddTenant();
        this.initAddPerson();
        this.initDatepickers();
        this.initAddAddress();
        this.initAddJointAddress();
        this.initHousingAccomodation();
        $('#housing-form-new-field-if-yes-please-provide-details-behavior').addClass('d-none');
        $('#housing-form-new-field-if-yes-please-provide-details-behavior textarea').prop('disabled', true);
        $('#action-taken').addClass('d-none');
        $('#housing-form-new-field-has-action-for-anti-social-behaviour-0').on('click', function(){
            if($('#housing-form-new-field-has-action-for-anti-social-behaviour-0').is(':checked')) {
                $('#housing-form-new-field-if-yes-please-provide-details-behavior').removeClass('d-none');
                $('#housing-form-new-field-if-yes-please-provide-details-behavior textarea').prop('disabled', false);
                $('#action-taken').removeClass('d-none');
            } 
        })
        $('#housing-form-new-field-has-action-for-anti-social-behaviour-1').on('click', function(){
            if($('#housing-form-new-field-has-action-for-anti-social-behaviour-1').is(':checked')) {
                $('#housing-form-new-field-if-yes-please-provide-details-behavior').addClass('d-none');
                $('#housing-form-new-field-if-yes-please-provide-details-behavior textarea').prop('disabled', true);
                $('#action-taken').addClass('d-none');
            } 
        })
    }
    initDatepickers()
    {
        this.$form.find('.datepicker').datepicker({
            format: 'dd/mm/yyyy',
        });
    }
    initNextButtons() {
        if($('.applydivshow').length ) {
            $(".applydivshow > .applydivsection").each(function(e) {
                totalStep = totalStep + e;
                if (e != 0)
                    $(this).hide();
            });
            var totalStep = 0;
            $(".next").click(function (e) {
                e.preventDefault();
                var groupname = $(this).data('group');
                var groupnum = $(this).data('grpnum');
                var firstelem = true;
                var secondelem = true;
                var thirdelem = true;
                var fourthelem = true;
                
                $('html, body').animate({
                    scrollTop: $("#content").offset().top}, 500);
                let group = $(this).closest('.group');
                if($('.datepicker').length) {
                    $('.datepicker').datepicker({
                        format: 'dd/mm/yyyy',
                    });
                }
                
                if (window.formidable.getForm('formidable_housing_form_new').validateGroup($(this).data('group'))) {
                    if ((groupname == 'household-details') && (groupnum == 4)) {
                        if($('#housing-form-new-field-proof-of-address-or-id-one-input').is(':visible') && ($('#housing-form-new-field-proof-of-address-or-id-one-input').val() == '')) {
                            var firstaddproof = '<div class="invalid-feedback">Address Proof is required.</div>';
                            $('#housing-form-new-field-proof-of-address-or-id-one-input').next('.errors').html(firstaddproof);
                            firstelem = false;
                        } else {
                            $('#housing-form-new-field-proof-of-address-or-id-one-input').next('.errors').empty();
                            firstelem = true;
                        }
                        if($('#housing-form-new-field-proof-of-address-or-id-two-input').is(':visible') && ($('#housing-form-new-field-proof-of-address-or-id-two-input').val() == '')) {
                            var secondaddproof = '<div class="invalid-feedback">Address Proof is required.</div>';
                            $('#housing-form-new-field-proof-of-address-or-id-two-input').next('.errors').html(secondaddproof);
                            secondelem = false;
                        } else {
                            $('#housing-form-new-field-proof-of-address-or-id-two-input').next('.errors').empty();
                            secondelem = true;
                        }
                        if($('#housing-form-new-field-proof-of-address-or-id-three-input').is(':visible') && ($('#housing-form-new-field-proof-of-address-or-id-three-input').val() == '')) {
                            var thirdaddproof = '<div class="invalid-feedback">Address Proof is required.</div>';
                            $('#housing-form-new-field-proof-of-address-or-id-three-input').next('.errors').html(thirdaddproof);
                            thirdelem = false;
                        } else {
                            $('#housing-form-new-field-proof-of-address-or-id-three-input').next('.errors').empty();
                            thirdelem = true;
                        }
                        if($('#housing-form-new-field-proof-of-address-or-id-four-input').is(':visible') && ($('#housing-form-new-field-proof-of-address-or-id-four-input').val() == '')) {
                            var fourthaddproof = '<div class="invalid-feedback">Address Proof is required.</div>';
                            $('#housing-form-new-field-proof-of-address-or-id-four-input').next('.errors').html(fourthaddproof);
                            fourthelem = false;
                        } else {
                            $('#housing-form-new-field-proof-of-address-or-id-four-input').next('.errors').empty();
                            fourthelem = true;
                        }
                        if (firstelem == true && secondelem == true && thirdelem == true && fourthelem == true) {
                            if ($(".applydivshow > .applydivsection:visible").next().length != 0) {
                                $(".applydivshow > .applydivsection:visible").next().show().prev().hide();
                            } else {
                                $(".applydivshow > .applydivsection:visible").hide();
                                $(".applydivshow > .applydivsection:first").show();
                            }
                        } else {
                            return false;
                        }
                    } else {
                        if ($(".applydivshow > .applydivsection:visible").next().length != 0) {
                            $(".applydivshow > .applydivsection:visible").next().show().prev().hide();
                        } else {
                            $(".applydivshow > .applydivsection:visible").hide();
                            $(".applydivshow > .applydivsection:first").show();
                        }
                        if ($(this).data('group') == 'equalities-monitoring-information') {
                            $('.next').hide();
                            $('.form-btn').removeClass('d-none').addClass('d-flex');
                        }
                    }
                } else {
                    let errors = group.find('.invalid-feedback');
                    if (errors.length) {
                        $('html, body').animate({
                            scrollTop: errors.first().offset().top - 400
                        }, 500);
                        $('.next').show();
                    }
                }
            });
        
            $(".prev").click(function(){
                $('.next').show();
                $('html, body').animate({
                    scrollTop: $("#content").offset().top}, 500);
                if ($(".applydivshow > .applydivsection:visible").prev().length != 0)
                    $(".applydivshow > .applydivsection:visible").prev().show().next().hide();
                else {
                    $(".applydivshow > .applydivsection:visible").hide();
                    $(".applydivshow > .applydivsection:last").show();
                }
                if (!$('.form-btn').hasClass('d-none')) {
                    $('.form-btn').addClass('d-none').removeClass('d-flex');
                }
                return false;
            });

            this.$form.find('.datepicker').datepicker({
                format: 'dd/mm/yyyy',
            });
        }
    }
    initAddTenant() {
        $('.js-add-tenant').click(() => {
            var current_add_person = $('#apply-additional-member').val();
            if(current_add_person == 4){
                alert('Sorry! Maximum 4 members are allowed');
            }
            else {
                var total_add_person = parseInt(current_add_person)+1;
                if(total_add_person == 4){
                    $('.js-add-tenant').css('visibility', 'hidden');
                }
                $('#apply-additional-member').val(total_add_person);
                this.addTenant(total_add_person);
            }
        });
    }
    addTenant(total_add_person) {
        //$('.group-additional-member-' + total_add_person).css('display', 'block');
        var divContent = $('.group-sec-additional-member-' + total_add_person).html();
        var html = '<div class="member'+total_add_person+'">';
        html += '<div class="text-uppercase fw-bold mb-_5">MEMBER ' + total_add_person + '</div>';
        html += '<div class="card-body'+total_add_person+'">' + divContent + '</div>';
        html += '<div class="group-close" data-group="'+ total_add_person+'">';
        html += '<button class="btn btn-sm btn-outline-secondary remove-group mb-2">- Remove Member ' + total_add_person +'</button>';
        html += '</div>';
        html += '</div>';
        $('.group-sec-household-details > .additional-member').append(html);
        $('.group-sec-additional-member-' + total_add_person).html('');
        if($('.datepicker').length) {
            $('.datepicker').datepicker({
                format: 'dd/mm/yyyy',
            });
        }
        this.initRemoveTenant();

    }
    initRemoveTenant() {
        $('.group-close').on('click', function(){
            // let member_id = $(this).data('group');
            let member_id = $(this).data('group');
            var divContent = $('.card-body' + member_id).html();
            $('.group-sec-additional-member-' + member_id).html(divContent);
            $('.member'+member_id).remove();
            if (member_id > 0 ){
                $('#apply-additional-member').val(member_id - 1);
            }
            if ((member_id - 1) < 4 ) {
                $('.js-add-tenant').css('visibility', 'visible');
            }
        })
    }
    initAddPerson() {
        $('.js-add-person').click(() => {
            var current_add_person = $('#apply-additional-person').val();
            if(current_add_person == 4){
                alert('Sorry! Maximum 4 persons are allowed');
            }
            else {
                var total_add_person = parseInt(current_add_person)+1;
                if(total_add_person == 4){
                    $('.js-add-person').css('visibility', 'hidden');
                }
                $('#apply-additional-person').val(total_add_person);
                this.addPerson(total_add_person);
            }
        });
    }
    addPerson(total_add_person) {
        //$('.group-additional-member-' + total_add_person).css('display', 'block');
        var divContent = $('.group-sec-willing-person-' + total_add_person).html();
        var html = '<div class="person'+total_add_person+'">';
        html += '<div class="text-uppercase fw-bold mb-_5">Person '+ total_add_person+ '</div>';
        html += '<div class="willing-body'+total_add_person+'">' + divContent + '</div>';
        html += '<div class="person-remove" data-group="'+ total_add_person+'">';
        html += '<button class="btn btn-sm btn-outline-secondary remove-group mb-2">- Remove Person ' + total_add_person +'</button>';
        html += '</div>';
        html += '</div>';
        $('.group-sec-willing-persons > .additional-member').append(html);
        $('.group-sec-willing-person-' + total_add_person).html('');
        if($('.datepicker').length) {
            $('.datepicker').datepicker({
                format: 'dd/mm/yyyy',
            });
        }
        this.initRemovePerson();

    }
    initRemovePerson() {
        $('.person-remove').on('click', function(){
            let member_id = $(this).data('group');
            var divContent = $('.willing-body' + member_id).html();
            $('.group-sec-willing-person-' + member_id).html(divContent);
            $('.person'+member_id).remove();
            
            if(member_id > 0 ){
                $('#apply-additional-person').val(member_id - 1);
            }
            if ((member_id - 1) < 4 ) {
                $('.js-add-person').css('visibility', 'visible');
            }
        })
    }
    initAddAddress() {
        $('.js-add-address-lead').click(() => {
            var current_add_address = $('#apply-additional-address').val();
            if(current_add_address == 4){
                alert('Sorry! Maximum 4 addresses are allowed');
            }
            else {
                var total_add_address = parseInt(current_add_address)+1;
                if(total_add_address == 4){
                    $('.js-add-address-lead').css('visibility', 'hidden');
                }
                $('#apply-additional-address').val(total_add_address);
                this.addAddress(total_add_address);
            }
        });
    }
    addAddress(total_add_address) {
        //$('.group-additional-member-' + total_add_person).css('display', 'block');
        var divContent = $('.group-sec-previous-accomodation-' + total_add_address).html();
        var html = '<div class="address'+total_add_address+'">';
        html += '<div class="text-uppercase fw-bold mb-_5">Address '+total_add_address+'</div>';
        html += '<div class="address-body'+total_add_address+'">' + divContent + '</div>';
        html += '<div class="address-remove" data-group="'+ total_add_address+'">';
        html += '<button class="btn btn-sm btn-outline-secondary remove-group mb-2">- Remove Address ' + total_add_address +'</button>';
        html += '</div>';
        html += '</div>';
        $('.group-sec-previous-address-lead-applicant > .additional-member').append(html);
        $('.group-sec-previous-accomodation-' + total_add_address).html('');
        if($('.datepicker').length) {
            $('.datepicker').datepicker({
                format: 'dd/mm/yyyy',
            });
        }
        this.initRemoveAddress();

    }
    initRemoveAddress() {
        $('.address-remove').on('click', function(){
            let member_id = $(this).data('group');
            var divContent = $('.address-body' + member_id).html();
            $('.group-sec-previous-accomodation-' + member_id).html(divContent);
            $('.address'+member_id).remove();
            if(member_id > 0 ){
                $('#apply-additional-address').val(member_id - 1);
            }
            if ((member_id - 1) < 4 ) {
                $('.js-add-address-lead').css('visibility', 'visible');
            }
        })
    }
    initAddJointAddress() {
        $('.js-add-address-joint').click(() => {
            var current_add_address = $('#apply-additional-address-joint').val();
            if(current_add_address == 4){
                alert('Sorry! Maximum 4 addresses are allowed');
            }
            else {
                var total_add_address = parseInt(current_add_address)+1;
                if(total_add_address == 4){
                    $('.js-add-address-joint').css('visibility', 'hidden');
                }
                $('#apply-additional-address-joint').val(total_add_address);
                this.addJointAddress(total_add_address);
            }
        });
    }
    addJointAddress(total_add_address) {
        //$('.group-additional-member-' + total_add_person).css('display', 'block');
        var divContent = $('.group-sec-previous-accomodation-joint-' + total_add_address).html();
        var html = '<div class="address-joint'+total_add_address+'">';
        html += '<div class="text-uppercase fw-bold mb-_5">Address '+total_add_address+'</div>';
        html += '<div class="address-body-joint'+total_add_address+'">' + divContent + '</div>';
        html += '<div class="joint-address-remove" data-group="'+ total_add_address+'">';
        html += '<button class="btn btn-sm btn-outline-secondary remove-group mb-2">- Remove Address ' + total_add_address +'</button>';
        html += '</div>';
        html += '</div>';
        $('.group-sec-previous-address-joint-applicant > .additional-member').append(html);
        $('.group-sec-previous-accomodation-joint-' + total_add_address).html('');
        if($('.datepicker').length) {
            $('.datepicker').datepicker({
                format: 'dd/mm/yyyy',
            });
        }
        this.initRemoveJointAddress();

    }
    initRemoveJointAddress() {
        $('.joint-address-remove').on('click', function(){
            let member_id = $(this).data('group');
            var divContent = $('.address-body-joint' + member_id).html();
            $('.group-sec-previous-accomodation-joint-' + member_id).html(divContent);
            $('.address-joint'+member_id).remove();
            if(member_id > 0 ){
                $('#apply-additional-address-joint').val(member_id - 1);
            }
            if ((member_id - 1) < 4 ) {
                $('.js-add-address-joint').css('visibility', 'visible');
            }
        })
    }
    initHousingAccomodation() {
        // console.log(this.showfile);
        // console.log(this.showother);
        if(this.showfile == 0) {
            $('#housing-form-new-field-home-accommodation-file').hide();
        }
        if(this.showother == 0) {
            $('#housing-form-new-field-other-housing-circumstances').hide();
        }
        $('#housing-form-new-field-current-housing-circumstances-2,#housing-form-new-field-current-housing-circumstances-3,#housing-form-new-field-current-housing-circumstances-4,#housing-form-new-field-current-housing-circumstances-9,#housing-form-new-field-current-housing-circumstances-10,#housing-form-new-field-current-housing-circumstances-11,#housing-form-new-field-current-housing-circumstances-13').on('click', function(){
            this.showfile = 1;
            this.showother = 0;
            $('#housing-form-new-field-home-accommodation-file').show();
            $('#housing-form-new-field-other-housing-circumstances').hide();
        });
        $('#housing-form-new-field-current-housing-circumstances-8,#housing-form-new-field-current-housing-circumstances-7,#housing-form-new-field-current-housing-circumstances-6,#housing-form-new-field-current-housing-circumstances-5,#housing-form-new-field-current-housing-circumstances-1,#housing-form-new-field-current-housing-circumstances-0,#housing-form-new-field-current-housing-circumstances-12').on('click', function(){
            this.showfile = 0;
            this.showother = 0;
            $('#housing-form-new-field-home-accommodation-file').hide();
            $('#housing-form-new-field-other-housing-circumstances').hide();
        });
        $('#housing-form-new-field-current-housing-circumstances-14').on('click', function(){
            this.showother = 1;
            this.showfile = 0;
            $('#housing-form-new-field-other-housing-circumstances').show();
            $('#housing-form-new-field-home-accommodation-file').hide();
        });
    }
    initFormSubmit() {
        let _this = this;
        this.$form.off('submit');
        this.$form.submit(function(e) {
            e.preventDefault();
            _this.hideSuccess();
            _this.removeErrors();
            if (formidable.validateForm(this, e)) {
                _this.disableSubmit()
                let url = $(this).attr('action');
                let formData = new FormData(this);
                $.ajax({
                    url: url,
                    data: formData,
                    contentType: false,
                    processData: false,
                    method: 'POST',
                    dataType: 'JSON'
                }).done((data) => {
                    if (data.success) {
                        // _this.showSuccess();
                        // _this.reset();
                        // $('.form-btn').removeClass('d-flex').addClass('d-none');
                        window.location.href = "apply/housing-application/thank-you";
                    }
                }).fail((response) => {
                    if (response.responseJSON.errors) {
                        _this.addErrors(response.responseJSON.errors);
                        _this.scrollToMessages();
                    }
                }).always(() => {
                    _this.reloadCaptcha();
                    _this.enableSubmit();
                });
            }
        });
    }

    reset()
    {
        this.$form.trigger('reset');
    }

    enableSubmit()
    {
        this.$form.find('[type=submit]').attr('disabled', false);
    }

    disableSubmit()
    {
        this.$form.find('[type=submit]').attr('disabled', true);
    }

    reloadCaptcha()
    {
        window.googleV3Captcha.getCaptcha(this.id).then((token) => {
            this.$form.find('[name=_recaptcha_response]').val(token);
        });
    }

    removeErrors()
    {
        if (this.$messages.length) {
            this.$messages.find('.alert-danger').remove();
        }
    }

    addErrors(errors)
    {
        if (this.$messages.length) {
            for (let i in errors) {
                let list = errors[i];
                for (let j in list) {
                    this.addError(list[j]);
                }
            }
        }
    }

    addError(error)
    {
        $('<div class="alert alert-danger">'+error+'</div>').appendTo(this.$messages);
    }

    scrollToMessages()
    {
        if (this.$messages.length) {
            $('html, body').animate({ scrollTop: this.$messages.offset().top - 170 }, 500);
        }
    }

    hideSuccess()
    {
        if (this.$messages.length) {
            this.$messages.find('.alert-success').hide();
        }
    }

    showSuccess()
    {
        if (this.$messages.length) {
            let success = this.$messages.find('.alert-success');
            if (success.length) {
                success.fadeIn();
                $('html, body').animate({ scrollTop: success.offset().top - 170 }, 500);
            }
        }
    }
}

export default HousingForm;