/*global $*/
import BaseClass from '../base/baseClass';
import {Modal} from 'bootstrap';
class Teams extends BaseClass
{
    init() {
        // console.log('Teams initialized');
        var _this = this;
        $('.openBtn').on('click',function(){
            var member_id = $(this).attr("data-id");
            _this.fetchMemberData(member_id, 'getMemberDetails');
        });
        $('.teamCat').on("click", function() {
            var member_id = $(this).attr("data-id");
            _this.fetchMemberData(member_id, 'getStaffDetails');
        });
    }
    fetchMemberData(member_id, url) {
        $('.modal-body').html('');
        $.ajax({
            type: 'get',
            url: '/' + url,
            dataType: 'html',
            data: { memberId: member_id },
            processData: true,
            success: function(result) {
                $('.modal-body').html(result);
            },
            error: function() {
                $('.modal-body').html('');
                var errorHtml = '';
                errorHtml += '<div class="col-12 col-md-12 itemFound">';
                errorHtml += '<span>Sorry! No relevant content found</span>';
                errorHtml += '</div>';
                $('.modal-body').html(errorHtml);
            }
        });
        var modal = new Modal(document.getElementById('teamModal'));
        modal.show();
    }
}

    

export default Teams;