/* global $ */
import 'slick-carousel';
import BaseClass from '../base/baseClass';

class Slick extends BaseClass
{
    init() {
        $('.slick-carousel').slick({
            autoplay: true,
        });

        $('.features-slider').slick({
            autoplay: true,
            arrows: true,
            slidesToShow: 3,
            speed: 1000,
            autoplaySpeed: 4000,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                }
            ]
        });

        $('.list-slider').slick({
            autoplay: true,
            arrows: true,
            slidesToShow: 3,
            speed: 1000,
            autoplaySpeed: 4000,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        });

        $('.search-slider').slick({
            autoplay: true,
            arrows: true,
            slidesToShow: 2,
            speed: 1000,
            autoplaySpeed: 4000,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        });

        $('.imgBtn-slider').slick({
            autoplay: true,
            arrows: true,
            slidesToShow: 3,
            speed: 1000,
            autoplaySpeed: 4000,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        });

        $('.values-slider').slick({
            autoplay: false,
            infinite: false,
            arrows: false,
            speed: 150,
            edgeFriction: 0,
            touchThreshold: 10,
            slidesToShow: 1,
            slidesToScroll: 1
        });

        $('.inner-testimonial-slider').slick({
            autoplay: true,
            arrows: false,
            dots: true,
            slidesToShow: 1,
            speed: 2000,
            autoplaySpeed: 20000,
            slidesToScroll: 1,
        });

        $('.news-slider').slick({
            autoplay: true,
            arrows: true,
            dots: false,
            slidesToShow: 1,
            speed: 1000,
            autoplaySpeed: 4000,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        });

        $('.gallery-slider').slick({
            autoplay: true,
            arrows: true,
            slidesToShow: 1,
            speed: 1000,
            autoplaySpeed: 4000,
            slidesToScroll: 1,
            asNavFor: '.gallery-nav-slider, .gallery-caption-slider'
        });

        $('.gallery-caption-slider').slick({
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 4000,
            slidesToShow: 1,
            slidesToScroll: 1,
            asNavFor: '.gallery-slider, .gallery-nav-slider',
            dots: false,
            focusOnSelect: true,
            arrows: false,
            fade: true

        });

        $('.gallery-nav-slider').slick({
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 4000,
            slidesToShow: 5,
            slidesToScroll: 1,
            asNavFor: '.gallery-slider, .gallery-caption-slider',
            dots: false,
            focusOnSelect: true,
            arrows: false,
            vertical:true,
            verticalSwiping:true,

        });

        $('.other-jobs-slider').slick({
            autoplay: true,
            arrows: true,
            slidesToShow: 1,
            speed: 1000,
            autoplaySpeed: 4000,
            slidesToScroll: 1,
        });

    }
}

export default Slick;