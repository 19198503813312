import App from './components/app';
import Slick from './components/slick';
import Forms from './components/forms';
import Teams from './components/teams';
import RequestReportForm from './components/request-report-form';
import ApplicationForm from './components/application-form';
import RetirementForm from './components/retirement-form';
import HousingForm from './components/housing-form';

window.App = new App();
window.Forms = new Forms();
window.Slick = new Slick();
window.Teams = new Teams();
window.RequestReportForm = RequestReportForm;
window.ApplicationForm = ApplicationForm;
window.RetirementForm = RetirementForm;
window.HousingForm = HousingForm;
